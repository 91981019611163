<template>
  <div class="distributionSettings">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="分销佣金比例设置" prop="shop_name">
        <el-radio-group v-model="ruleForm.setting">
          <el-radio :label="1">统一商品设置</el-radio>
          <el-radio :label="2">针对商品来源设置</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 统一商品设置 -->
      <template v-if="ruleForm.setting == 1">
        <el-form-item  prop="shop_name">
          <el-radio-group v-model="ruleForm.goodSetting.classification">
            <el-radio :label="1">按全部分类设置</el-radio>
            <el-radio :label="2">按指定分类设置</el-radio>
          </el-radio-group>
          <p v-if="ruleForm.goodSetting.classification==1">
            获取下级C端用户下单支付金额的
            <el-input 
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
            size="mini" style="width:100px" placeholder="" v-model.trim="ruleForm.goodSetting.goodInput" clearable />%，作为上级分销佣金。
          </p>
          <template v-if="ruleForm.goodSetting.classification==2">
            <p>根据指定分类上所设置的返佣比例，以及所对应商品的销售价，作为上级用户获取下级C端用户的分销佣金。</p>
            <el-classification ref="allClassification" :parentData="ruleForm.goodSetting.allList" />
          </template>
        </el-form-item>
      </template>
      <!-- 统一商品设置 -->
      <template v-if="ruleForm.setting == 2">
        <el-form-item  prop="shop_name">
          <el-checkbox v-model="ruleForm.goodSoureSetting.JDchecked">京东商品</el-checkbox>
        </el-form-item>
        <el-form-item prop="shop_name" v-show="ruleForm.goodSoureSetting.JDchecked">
          <el-radio-group v-model="ruleForm.goodSoureSetting.JDradio">
            <el-radio :label="1">按全部分类设置</el-radio>
            <el-radio :label="2">按指定分类设置</el-radio>
          </el-radio-group>
          <p v-if="ruleForm.goodSoureSetting.JDradio==1">
            获取下级C端用户下单支付金额的
            <el-input 
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
            size="mini" style="width:100px" placeholder="" v-model.trim="ruleForm.goodSoureSetting.JDinput" clearable />%，作为上级分销佣金。
          </p>
          <template v-if="ruleForm.goodSoureSetting.JDradio==2">
            <p>根据指定分类上所设置的返佣比例，以及所对应商品的销售价，作为上级用户获取下级C端用户的分销佣金。</p>
            <el-classification ref="JDClassification" :parentData="ruleForm.goodSoureSetting.JDList" />
          </template>
        </el-form-item>

        <el-form-item  prop="shop_name">
          <el-checkbox v-model="ruleForm.goodSoureSetting.JXchecked">精选优品</el-checkbox>
        </el-form-item>
        <el-form-item  prop="shop_name" v-show="ruleForm.goodSoureSetting.JXchecked">
          <el-radio-group v-model="ruleForm.goodSoureSetting.JXradio">
            <el-radio :label="1">按全部分类设置</el-radio>
            <el-radio :label="2">按指定分类设置</el-radio>
          </el-radio-group>
          <p v-if="ruleForm.goodSoureSetting.JXradio==1">
            获取下级C端用户下单支付金额的
            <el-input 
            onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
            oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
            size="mini" style="width:100px" placeholder="" v-model.trim="ruleForm.goodSoureSetting.JXinput" clearable />%，作为上级分销佣金。
          </p>
          <template v-if="ruleForm.goodSoureSetting.JXradio==2">
            <p>根据指定分类上所设置的返佣比例，以及所对应商品的销售价，作为上级用户获取下级C端用户的分销佣金。</p>
            <el-classification ref="JXClassification" :parentData="ruleForm.goodSoureSetting.JXList" />
          </template>
        </el-form-item>

        <!-- 企业自有商品 -->
        <template v-if="has_self_goods">
          <el-form-item  prop="shop_name">
            <el-checkbox v-model="ruleForm.goodSoureSetting.QYchecked">企业自有商品</el-checkbox>
          </el-form-item>
          <el-form-item prop="shop_name" v-show="ruleForm.goodSoureSetting.QYchecked">
            <el-radio-group v-model="ruleForm.goodSoureSetting.QYradio">
              <el-radio :label="1">按全部分类设置</el-radio>
              <el-radio :label="2">按指定分类设置</el-radio>
            </el-radio-group>
            <p v-if="ruleForm.goodSoureSetting.QYradio==1">
              获取下级C端用户下单支付金额的
              <el-input 
              onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
              oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
              size="mini" style="width:100px" placeholder="" v-model.trim="ruleForm.goodSoureSetting.QYinput" clearable />%，作为上级分销佣金。
            </p>
            <template v-if="ruleForm.goodSoureSetting.QYradio==2">
              <p>根据指定分类上所设置的返佣比例，以及所对应商品的销售价，作为上级用户获取下级C端用户的分销佣金。</p>
              <el-classification ref="QYClassification" :parentData="ruleForm.goodSoureSetting.QYList" />
            </template>
          </el-form-item>
        </template>
        <!-- 企业自有供应商商品 -->
        <template v-if="numof_enterprise_owner_suppliers">
          <el-form-item  prop="shop_name">
            <el-checkbox v-model="ruleForm.goodSoureSetting.ZYchecked">企业自有供应商商品</el-checkbox>
          </el-form-item>
          <el-form-item  prop="shop_name" v-show="ruleForm.goodSoureSetting.ZYchecked">
            <el-radio-group v-model="ruleForm.goodSoureSetting.ZYradio">
              <el-radio :label="1">按全部分类设置</el-radio>
              <el-radio :label="2">按指定分类设置</el-radio>
            </el-radio-group>
            <p v-if="ruleForm.goodSoureSetting.ZYradio==1">
              获取下级C端用户下单支付金额的
              <el-input 
              onblur="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)&&value!=100) value=''"
              oninput="if(!/^(?!0{2,})(\d{1,2}|\d{1,2}\.\d{1,2})$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value>100)value=100;if(value<0)value=null;if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
              size="mini" style="width:100px" placeholder="" v-model.trim="ruleForm.goodSoureSetting.ZYinput" clearable />%，作为上级分销佣金。
            </p>
            <template v-if="ruleForm.goodSoureSetting.ZYradio==2">
              <p>根据指定分类上所设置的返佣比例，以及所对应商品的销售价，作为上级用户获取下级C端用户的分销佣金。</p>
              <el-classification  ref="ZYClassification" :parentData="ruleForm.goodSoureSetting.ZYList"/>
            </template>
          </el-form-item>
        </template>
      </template>
      <div class="fixed_btn">
        <el-button style="width:150px" type="primary" @click="save">保存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getShopExtAuth } from '@/api/order'
import { getDistribution,saveDistribution } from '@/api/systemSetting'
export default {
  name: "distributionSettings",
  data () {
    return {
      ruleForm: {
        setting: 1,
        goodSetting:{//统一商品设置
          classification:1,
          goodInput: '',
          allList:[]////统一设置的指定分类
        },
        goodSoureSetting:{//针对商品来源设置
          JDchecked:'',//京东
          JDradio:1,
          JDinput: '',
          JDList: [],//设置的京东分类
          JXchecked:'',//精选
          JXradio:1,
          JXinput: '',
          JXList: [],//设置的精选分类
          QYchecked:'',//企业自有
          QYradio:1,
          QYinput: '',
          QYList: [],//设置的企业自有分类
          ZYchecked:'',//自有供应商
          ZYradio:1,
          ZYinput: '',
          ZYList: []//设置的自有供应商分类
        }
      },
      rules: {
        radio: [
          { required: true, message: '请选择分销佣金比例设置', trigger: 'change' }
        ]
      },
      numof_enterprise_owner_suppliers: false,//是否显示自有供应商
      has_self_goods: false,//是否显示企业自有商品
    }
  },
  async mounted () {
    const resStatus = await getShopExtAuth().then();
    if (resStatus) {
      //是否有自有供应商 -1：关闭   大于-1：开启
      this.numof_enterprise_owner_suppliers = resStatus.numof_enterprise_owner_suppliers !== -1;
       //是否有企业自有商品 
      this.has_self_goods = resStatus.has_self_goods === 1
    }
    //查询保存的分销设置
    this.getSetting()
  },
  methods: {
    /**查询分销设置 */
    getSetting () {
      const params = {
        page_no: 1,
        page_size: 9999,
        biz_type: 0
      }
      getDistribution(params).then(res => {
        if (res.data[0].setting_type==-1) {//统一商品设置
          this.ruleForm.setting=1
          if (res.data[0].category_id == -1) {//全部分类设置
            this.ruleForm.goodSetting.classification = 1  
            this.ruleForm.goodSetting.goodInput=res.data[0].value
          } else {//指定分类设置
            this.ruleForm.goodSetting.classification = 2
            this.ruleForm.goodSetting.allList=res.data
          }
        } else {//针对商品来源设置
          this.ruleForm.setting = 2
          res.data.forEach(item => {
            if (item.setting_type == 1) {//京东
              this.ruleForm.goodSoureSetting.JDchecked = true
              if (item.category_id==-1) {//判断是全部分类还是指定分类
                this.ruleForm.goodSoureSetting.JDradio = 1
                this.ruleForm.goodSoureSetting.JDinput=item.value
              }else{
                this.ruleForm.goodSoureSetting.JDradio = 2
                this.ruleForm.goodSoureSetting.JDList.push(item)
              }
            } 
            if (item.setting_type == 0) {//精选
              this.ruleForm.goodSoureSetting.JXchecked = true
              if (item.category_id==-1) {//判断是全部分类还是指定分类
                this.ruleForm.goodSoureSetting.JXradio = 1
                this.ruleForm.goodSoureSetting.JXinput=item.value
              }else{
                this.ruleForm.goodSoureSetting.JXradio = 2
                this.ruleForm.goodSoureSetting.JXList.push(item)
              }
            }
            if (item.setting_type == 2) {//企业自有
              this.ruleForm.goodSoureSetting.QYchecked = true
              if (item.category_id==-1) {//判断是全部分类还是指定分类
                this.ruleForm.goodSoureSetting.QYradio = 1
                this.ruleForm.goodSoureSetting.QYinput=item.value
              }else{
                this.ruleForm.goodSoureSetting.QYradio = 2
                this.ruleForm.goodSoureSetting.QYList.push(item)
              }
            }
            if (item.setting_type == 9) {//自有供应商
              this.ruleForm.goodSoureSetting.ZYchecked = true
              if (item.category_id==-1) {//判断是全部分类还是指定分类
                this.ruleForm.goodSoureSetting.ZYradio = 1
                this.ruleForm.goodSoureSetting.ZYinput=item.value
              }else{
                this.ruleForm.goodSoureSetting.ZYradio = 2
                this.ruleForm.goodSoureSetting.ZYList.push(item)
              }
            }
          })
        }
      })
    },
    /**保存 */
    save () {
      let formData=[]
      if (this.ruleForm.setting==1) {//统一商品设置
        if (this.ruleForm.goodSetting.classification == 1) {//按照全部分类设置
          const obj = {
            biz_type: 0,//写死
            rule_type: 2,//写死
            setting_type: -1,
            value: Number(this.ruleForm.goodSetting.goodInput),
            category_id: -1,
            category_path: -1
          }
          formData.push(obj)
        } else {//按指定分类设置
          let dataList = this.$refs.allClassification.goOutform()
          formData = dataList.map(item => {
            return {
              biz_type: 0,//写死
              rule_type: 2,//写死
              setting_type: -1,
              value: Number(item.commission),
              category_id: item.category_id,
              category_path: item.category_path
            }
          }) 
        }
      } else {//针对商品来源设置
        let JDdataList = this.JDformat()//京东
        let JXdataList = this.JXformat()//精选
        let QYdataList = this.QYformat()//自有
        let ZYdataList = this.ZYformat()//自有供应商
        formData=[...JDdataList,...JXdataList,...QYdataList,...ZYdataList]
      }
      saveDistribution(formData).then(() => {
        this.$message.success('保存成功！')
      }).catch(() => {
        this.$message.error('保存失败！')
      })
    },
    /**处理京东商品来源分销设置比例数据格式 */
    JDformat () {
      if(this.ruleForm.goodSoureSetting.JDchecked==false) return []
      if (this.ruleForm.goodSoureSetting.JDradio==1) {//全部分类
        return [{
          biz_type: 0,//写死
          rule_type: 2,//写死
          setting_type: 1,
          value: Number(this.ruleForm.goodSoureSetting.JDinput),
          category_id: -1,
          category_path: -1
        }]
      } else {//指定分类
        let dataList = this.$refs.JDClassification.goOutform()
        let formData = dataList.map(item => {
          return {
            biz_type: 0,//写死
            rule_type: 2,//写死
            setting_type: 1,
            value: Number(item.commission),
            category_id: item.category_id,
            category_path: item.category_path
          }
        }) 
        return formData
      } 
    },
    /**处理精选优品商品来源分销设置比例数据格式 */
    JXformat () {
      if(this.ruleForm.goodSoureSetting.JXchecked==false) return []
      if (this.ruleForm.goodSoureSetting.JXradio==1) {//全部分类
        return [{
          biz_type: 0,//写死
          rule_type: 2,//写死
          setting_type: 0,
          value: Number(this.ruleForm.goodSoureSetting.JXinput),
          category_id: -1,
          category_path: -1
        }]
      } else {//指定分类
        let dataList = this.$refs.JXClassification.goOutform()
        let formData = dataList.map(item => {
          return {
            biz_type: 0,//写死
            rule_type: 2,//写死
            setting_type: 0,
            value: Number(item.commission),
            category_id: item.category_id,
            category_path: item.category_path
          }
        }) 
        return formData
      } 
    },
    /**处理企业自有商品来源分销设置比例数据格式 */
    QYformat () {
      if(this.ruleForm.goodSoureSetting.QYchecked==false) return []
      if (this.ruleForm.goodSoureSetting.QYradio==1) {//全部分类
        return [{
          biz_type: 0,//写死
          rule_type: 2,//写死
          setting_type: 2,
          value: Number(this.ruleForm.goodSoureSetting.QYinput),
          category_id: -1,
          category_path: -1
        }]
      } else {//指定分类
        let dataList = this.$refs.QYClassification.goOutform()
        let formData = dataList.map(item => {
          return {
            biz_type: 0,//写死
            rule_type: 2,//写死
            setting_type: 2,
            value: Number(item.commission),
            category_id: item.category_id,
            category_path: item.category_path
          }
        }) 
        return formData
      } 
    },
    /**处理企业自有供应商商品来源分销设置比例数据格式 */
    ZYformat () {
      if(this.ruleForm.goodSoureSetting.ZYchecked==false) return []
      if (this.ruleForm.goodSoureSetting.ZYradio==1) {//全部分类
        return [{
          biz_type: 0,//写死
          rule_type: 2,//写死
          setting_type: 9,
          value: Number(this.ruleForm.goodSoureSetting.ZYinput),
          category_id: -1,
          category_path: -1
        }]
      } else {//指定分类
        let dataList = this.$refs.ZYClassification.goOutform()
        let formData = dataList.map(item => {
          return {
            biz_type: 0,//写死
            rule_type: 2,//写死
            setting_type: 9,
            value: Number(item.commission),
            category_id: item.category_id,
            category_path: item.category_path
          }
        }) 
        return formData
      } 
    },
    /**重置表单 */
    resetForm () {
      this.ruleForm= {
        setting: 1,
        goodSetting:{//统一商品设置
          classification:1,
          goodInput: '',
          allList:[]////统一设置的指定分类
        },
        goodSoureSetting:{//针对商品来源设置
          JDchecked:'',//京东
          JDradio:1,
          JDinput: '',
          JDList: [],//设置的京东分类
          JXchecked:'',//精选
          JXradio:1,
          JXinput: '',
          JXList: [],//设置的精选分类
          QYchecked:'',//企业自有
          QYradio:1,
          QYinput: '',
          QYList: [],//设置的企业自有分类
          ZYchecked:'',//自有供应商
          ZYradio:1,
          ZYinput: '',
          ZYList: []//设置的自有供应商分类
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
 .distributionSettings{
  background: #fff;
  min-height: 90vh;
 }
 .el-form-item {
    margin-bottom: 0;
  }
  .fixed_btn{
    width: 100%;
    padding:15px;
    position: fixed;
    bottom: 0px;
    z-index: 9;
    background: #ebeef5;
  }
  .demo-ruleForm{
    padding-bottom: 100px;
  }
</style>